import request from "./request";
import { getDealerId } from "./user";
// 公用
export const commonApi = {
  // 公众号配置
  getJsapiTicket: (data) => {
    return request("POST", "/wechat/jsapi/getJsapiTicket", {}, eval(data));
  },
  // 微信语音
  getMediaUrl: (data) => {
    return request("POST", "/wechat/wx/media/getMediaUrl", {}, data);
  },
  // 字典
  dictList: (data) => {
    return request("POST", "/appdealer/api/dict/get_DictList", {}, data);
  },
  // 省
  provinceList: () => {
    return request(
      "POST",
      "/appdealer/api/area/get_provinceByDealer",
      {},
      { dealer: getDealerId() }
    );
  },
  // 市
  cityList: (data) => {
    return request("POST", "/appdealer/api/area/get_cityByDealer", {}, data);
  },
  // 区县
  regionList: (data) => {
    return request("POST", "/appdealer/api/area/get_regionByDealer", {}, data);
  },
  regionLevel: (data) => {
    return request("POST", "/appdealer/api/area/get_region_level", {}, data);
  },
  // 乡镇
  townList: (data) => {
    return request("POST", "/appdealer/api/area/get_town", {}, data);
  },
  // 村
  villageList: (data) => {
    return request("POST", "/appdealer/api/area/get_village", {}, data);
  },
  // 文件
  upload: (data) => {
    return request("MULTI-PART", "/file/appupload", {}, data, {
      Accept: "*/*",
      "Content-Type": "multipart/form-data",
    });
  },
};

// 经销商模块
export const dealerApi = {
  // 经销商列表查询
  list: () => {
    return request("POST", "/appdealer/api/dealer/get_dealerList");
  },
};

// 空白村模块
export const emptyAreaApi = {
  // 获取空白村列表
  list: (data) => {
    return request(
      "POST",
      "/appdealer/api/blankVillage/get_blankVillageList3",
      {},
      data
    );
  },
  // 获取拜访列表
  visitList: (data) => {
    return request(
      "POST",
      "/appdealer/api/blankVillage/get_blankVillageVisitList",
      {},
      data
    );
  },
  // 获取详情
  detail: (data) => {
    return request(
      "POST",
      "/appdealer/api/blankVillage/get_blankVillageById",
      {},
      data
    );
  },
  // 保存
  infoSave: (data) => {
    return request(
      "POST",
      "/appdealer/api/blankVillage/save_blankVillageVisit1",
      {},
      data
    );
  },
  saveVisit: (data) => {
    return request(
      "POST",
      "/appdealer/api/blankVillage/save_blankVillageVisit2",
      {},
      data
    );
  },
  // 获取空白乡镇列表
  townList: (data) => {
    return request(
      "POST",
      "/appdealer/api/blankVillage/get_blankTownList",
      {},
      data
    );
  },
};

// 农技会
// 无拜访
export const agriculturalTechnologyApi = {
  // 史丹利
  list: (data) => {
    return request(
      "POST",
      "/appdealer/api/techconference/get_techconferenceList",
      {},
      data
    );
  },
  detail: (data) => {
    return request(
      "POST",
      "/appdealer/api/techconference/get_techconferenceById",
      {},
      data
    );
  },
  save: (data) => {
    return request(
      "POST",
      "/appdealer/api/techconference/save_techconference",
      {},
      data
    );
  },
  addFertilizer: (data) => {
    return request(
      "POST",
      "/appdealer/api/techconference/save_techconferenceOrder",
      {},
      data
    );
  },
  // 自有
  distList: (data) => {
    return request(
      "POST",
      "/appdealer/api/dist/techconference/get_techconferenceList",
      {},
      data
    );
  },
  distDetail: (data) => {
    return request(
      "POST",
      "/appdealer/api/dist/techconference/get_techconferenceById",
      {},
      data
    );
  },
  distSave: (data) => {
    return request(
      "POST",
      "/appdealer/api/dist/techconference/save_techconference",
      {},
      data
    );
  },
  distAddFertilizer: (data) => {
    return request(
      "POST",
      "/appdealer/api/dist/techconference/save_techconferenceOrder",
      {},
      data
    );
  },
  distDelete: (data) => {
    return request(
      "POST",
      "/appdealer/api/dist/techconference/delete",
      {},
      data
    );
  },
};

// 潜在客户
export const potentialCustomersApi = {
  // 史丹利潜在客户列表
  list: (data) => {
    return request(
      "POST",
      "/appdealer/api/potential/list",
      {},
      data,
      {},
      { isRequestWithCoordinate: true }
    );
  },
  detail: (data) => {
    return request("POST", "/appdealer/api/potential/get", {}, data);
  },
  save: (data) => {
    return request("POST", "/appdealer/api/potential/save", {}, data);
  },
  saveVisit: (data) => {
    return request("POST", "/appdealer/api/potential/visit/save", {}, data);
  },
  record: (data) => {
    return request(
      "POST",
      "/appdealer/api/potential/visit/get_record",
      {},
      data
    );
  },
  // 自有潜在客户列表
  distList: (data) => {
    return request(
      "POST",
      "/appdealer/api/dist/potential/list",
      {},
      data,
      {},
      { isRequestWithCoordinate: true }
    );
  },
  distDetail: (data) => {
    return request("POST", "/appdealer/api/dist/potential/get", {}, data);
  },
  // 基本信息 + 新增
  distSave: (data) => {
    return request("POST", "/appdealer/api/dist/potential/save", {}, data);
  },
  distSaveVisit: (data) => {
    return request(
      "POST",
      "/appdealer/api/dist/potential/visit/save",
      {},
      data
    );
  },
  distRecord: (data) => {
    return request(
      "POST",
      "/appdealer/api/dist/potential/visit/get_record",
      {},
      data
    );
  },
};

// 示范田
export const demonstrationFieldApi = {
  // 史丹利示范田列表
  list: (data) => {
    return request(
      "POST",
      "/appdealer/api/modelfield/list",
      {},
      data,
      {},
      { isRequestWithCoordinate: true }
    );
  },
  // 示范田详情
  detail: (data) => {
    return request("POST", "/appdealer/api/modelfield/get", {}, data);
  },
  // 示范田拜访记录
  record: (data) => {
    return request(
      "POST",
      "/appdealer/api/modelfield/visit/get_record",
      {},
      data
    );
  },
  // 基本信息
  save: (data) => {
    return request("POST", "/appdealer/api/modelfield/save", {}, data);
  },
  // 拜访小结
  saveVisit: (data) => {
    return request("POST", "/appdealer/api/modelfield/visit/save", {}, data);
  },
  // 自有示范田列表
  distList: (data) => {
    return request(
      "POST",
      "/appdealer/api/dist/modelfield/list",
      {},
      data,
      {},
      { isRequestWithCoordinate: true }
    );
  },
  // 自有示范田详情
  distDetail: (data) => {
    return request("POST", "/appdealer/api/dist/modelfield/get", {}, data);
  },
  // 自有示范田拜访记录
  distRecord: (data) => {
    return request(
      "POST",
      "/appdealer/api/dist/modelfield/visit/get_record",
      {},
      data
    );
  },
  // 基本信息 + 新增
  distSave: (data) => {
    return request("POST", "/appdealer/api/dist/modelfield/save", {}, data);
  },
  // 拜访小结
  distSaveVisit: (data) => {
    return request(
      "POST",
      "/appdealer/api/dist/modelfield/visit/save",
      {},
      data
    );
  },
};

// 终端
export const terminalOutletsApi = {
  // 史丹利
  list: (data) => {
    return request(
      "POST",
      "/appdealer/api/terminal/list",
      {},
      data,
      {},
      { isRequestWithCoordinate: true }
    );
  },
  detail: (data) => {
    return request("POST", "/appdealer/api/terminal/get", {}, data);
  },
  save: (data) => {
    return request("POST", "/appdealer/api/terminal/save", {}, data);
  },
  // 拜访小结
  saveVisit: (data) => {
    return request("POST", "/appdealer/api/terminal/visit/tip/save", {}, data);
  },
  // 自有
  distList: (data) => {
    return request(
      "POST",
      "/appdealer/api/dist/terminal/list",
      {},
      data,
      {},
      { isRequestWithCoordinate: true }
    );
  },
  distDetail: (data) => {
    return request("POST", "/appdealer/api/dist/terminal/get", {}, data);
  },
  distDelete: (data) => {
    return request("POST", "/appdealer/api/dist/terminal/delete", {}, data);
  },
  // 基本信息 + 新增
  distSave: (data) => {
    return request("POST", "/appdealer/api/dist/terminal/save", {}, data);
  },
  // 拜访小结
  distSaveVisit: (data) => {
    return request(
      "POST",
      "/appdealer/api/dist/terminal/visit/tip/save",
      {},
      data
    );
  },
  // 核验信息
  lastRecord: (data) => {
    return request(
      "POST",
      "/appdealer/api/terminal/visit/build/get_last_record",
      {},
      data
    );
  },
  checkSave: (data) => {
    return request("POST", "/appdealer/api/terminal/check/save", {}, data);
  },
  matterSave: (data) => {
    return request(
      "POST",
      "/appdealer/api/terminal/visit/build/get_last_record",
      {},
      data
    );
  },
  // 作物覆盖
  plantList: (data) => {
    return request("POST", "/appdealer/api/plant/list", {}, data);
  },
  plantListByTerminal: (data) => {
    return request(
      "POST",
      "/appdealer/api/terminal/cover_plant/list",
      {},
      data
    );
  },
  coverPlantSave: (data) => {
    return request(
      "POST",
      "/appdealer/api/terminal/cover_plant/cover",
      {},
      data
    );
  },
  // 乡镇覆盖
  townList: (data) => {
    return request(
      "POST",
      "/appdealer/api/area/dealer_cover_town/list",
      {},
      data
    );
  },
  villageList: (data) => {
    return request("POST", "/appdealer/api/area/get_village", {}, data);
  },
  villageListByTerminal: (data) => {
    return request(
      "POST",
      "/appdealer/api/terminal/cover_village/list",
      {},
      data
    );
  },
  coverVillageSave: (data) => {
    return request(
      "POST",
      "/appdealer/api/terminal/cover_village/cover",
      {},
      data
    );
  },
};

// 种植大户
// 基本信息/新增 分开
export const bigGrowersApi = {
  // 史丹利
  list: (data) => {
    return request(
      "POST",
      "/appdealer/api/bigfarmers/get_bigFarmersList",
      {},
      data,
      {},
      { isRequestWithCoordinate: true }
    );
  },
  detail: (data) => {
    return request(
      "POST",
      "/appdealer/api/bigfarmers/get_bigFarmersById",
      {},
      data
    );
  },
  record: (data) => {
    return request(
      "POST",
      "/appdealer/api/bigfarmers/get_bigFarmersVisitList",
      {},
      data
    );
  },
  save: (data) => {
    return request(
      "POST",
      "/appdealer/api/bigfarmers/save_bigFarmersVisit1",
      {},
      data
    );
  },
  saveVisit: (data) => {
    return request(
      "POST",
      "/appdealer/api/bigfarmers/save_bigFarmersVisit2",
      {},
      data
    );
  },
  // 自有
  distList: (data) => {
    return request(
      "POST",
      "/appdealer/api/dist/bigfarmers/list",
      {},
      data,
      {},
      { isRequestWithCoordinate: true }
    );
  },
  distDetail: (data) => {
    return request("POST", "/appdealer/api/dist/bigfarmers/get", {}, data);
  },
  distRecord: (data) => {
    return request("POST", "/dist/bigfarmers/visit/list", {}, data);
  },
  distSave: (data) => {
    return request("POST", "/appdealer/api/dist/bigfarmers/save", {}, data);
  },
  distDelete: (data) => {
    return request("POST", "/appdealer/api/dist/bigfarmers/delete", {}, data);
  },
  distSaveInfo: (data) => {
    return request("POST", "/appdealer/api/dist/bigfarmers/update", {}, data);
  },
  distSaveVisit: (data) => {
    return request(
      "POST",
      "/appdealer/api/dist/bigfarmers/visit/save",
      {},
      data
    );
  },
};

// 业务相关
export const shidanliBusinessApi = {
  salemanList: (data) => {
    return request("POST", "/appdealer/api/saleman/list", {}, data);
  },
  salemanAdd: (data) => {
    return request("POST", "/appdealer/api/saleman/add", {}, data);
  },
  salemanDelete: (data) => {
    return request("POST", "/appdealer/api/saleman/delete", {}, data);
  },
  salemanDetail: (data) => {
    return request("POST", "/appdealer/api/saleman/get", {}, data);
  },
  taskList: (data) => {
    return request(
      "POST",
      "/appdealer/api/taskrecord/getGetTaskRecordList",
      {},
      data
    );
  },
  giveTaskList: (data) => {
    return request(
      "POST",
      "/appdealer/api/taskrecord/getGiveTaskRecordList",
      {},
      data
    );
  },
};
