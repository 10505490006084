import axios from "axios";
import { getToken, getUserName } from "./user";
import { initMap, initCurrentLocation } from "@/utils/map";
import * as user from "@/utils/user";

export const get = async (url, params, options = {}) => {
  return await request("GET", url, params, {}, options.header);
};

export const post = async (url, data, options = {}) => {
  return await request("POST", url, {}, data, options.header);
};

export const login = async (url, data, options = {}) => {
  return await request("LOGIN", url, {}, data, options.header);
};

export const upload = async (data) => {
  return await request("MULTI-PART", "/wechat/wx/media/wxupload", {}, data, {
    Accept: "*/*",
    "Content-Type": "multipart/form-data",
  });
};

export default async function request(
  method,
  url,
  params = {},
  data = {},
  header = {},
  options = {}
) {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...header,
  };

  let response = {};

  try {
    switch (method) {
      case "GET": {
        response = await axios.get(encodeUrlWithParams(url, params), {
          headers,
        });
        break;
      }
      case "POST": {
        const requestData = await encodeData(
          data,
          options.isRequestWithCoordinate
        );
        response = await axios.post(
          encodeUrlWithParams(url, params),
          JSON.stringify(requestData),
          {
            headers,
          }
        );
        break;
      }
      case "LOGIN": {
        response = await axios.post(
          encodeUrlWithParams(url, params),
          JSON.stringify(data),
          {
            headers,
          }
        );
        break;
      }
      case "DELETE": {
        response = await axios.delete(encodeUrlWithParams(url, params), {
          headers,
        });
        break;
      }
      case "MULTI-PART": {
        const params = new FormData();
        if (data) {
          params.append("file", data.file);
        }
        const config = {
          headers,
        };
        response = await axios.post(
          encodeUrlWithParams(url, params),
          params,
          config
        );
        break;
      }
    }
  } catch (error) {
    response = error;
  }

  const responseData = response.data || {};

  // 跳过options请求
  if (response.config.method === "options") {
    return responseData;
  }

  // 正确返回
  if (
    parseInt(responseData.status) === 0 ||
    parseInt(responseData.status) === 2 ||
    parseInt(responseData.status) === -1 ||
    parseInt(responseData.code) === 200 ||
    responseData.appId
  ) {
    return responseData;
  }

  // 未登录跳转处理
  if (responseData.status === 20011 || responseData.status === 9999 || responseData.status === 7777) {
    user.logout();
    window.vm && window.vm.$toast(responseData.msg);
    window.setTimeout(() => {
      window.vm.$router.push({ name: "Login",params:{refresh:true} });
    }, 1000);
  }

    if (parseInt(responseData.status) === 1 || parseInt(responseData.code) === 500) {
        window.vm && window.vm.$toast(responseData.msg);
    }

  // 错误处理
  const error = new Error(`${responseData.msg || "未知错误"}`);
  error.response = responseData;
  throw error;
}

async function encodeData(data, isRequestWithCoordinate) {
  if (isRequestWithCoordinate) {
    data.coordinate = await getCurrentCoordinate();
  }
  return {
    client: "h5",
    token: getToken(),
    param: {
      loginName: getUserName(),
      ...data,
    },
    did: "",
    loginName: getUserName(),
  };
}

function encodeUrlWithParams(url, params) {
  Object.keys(params).forEach((key, index) => {
    url += `${index ? "" : "?"}`;
    if (params[key]) {
      url += `${index ? "&" : ""}${key}=${encodeURIComponent(params[key])}`;
    }
  });

  return url;
}

let currentMap = "";
let currentRequestLocation = "";
async function getCurrentCoordinate() {
  try {
    if (!currentRequestLocation) {
      if (!currentMap) {
        const { map, currentLocation } = await initMap("requestMapContainer", {
          currentLocation: true,
        });
        currentMap = map;
        currentRequestLocation = currentLocation || {};
      } else {
        currentRequestLocation = await initCurrentLocation(currentMap, {});
      }
    }
    return currentRequestLocation.lng && currentRequestLocation.lat
      ? `${currentRequestLocation.lng},${currentRequestLocation.lat}`
      : "";
  } catch (err) {
    return "";
  }
}
