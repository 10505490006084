import Vue from 'vue'
import VueRouter from 'vue-router'

const originBack = VueRouter.prototype.back;
VueRouter.prototype.back = function (timeout) {
  window.setTimeout(() => {
    originBack.call(this)
  }, timeout || 1000)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/index'),
    meta: {
      title: '首页'
    }
  },

  {
    path: '/certificate',
    name: 'Certificate',
    component: () => import(/* webpackChunkName: "target" */ '@/views/login/certificate'),
    meta: {
      title: '实名校验'
    }
  },

  {
    path: '/product/detail',
    name: 'productDetail',
    component: () => import(/* webpackChunkName: "notification" */ '../views/product/detail'),
    meta: {
      title: '产品详情',
      keepAlive: true // 需要被缓存
    }
  },

  {
    path: '/product/car',
    name: 'productCar',
    component: () => import(/* webpackChunkName: "notification" */ '../views/product/car'),
    meta: {
      title: '购物车',
      keepAlive: true // 需要被缓存
    }
  },
    
  {
    path: '/channel',
    name: 'Channel',
    component: () => import(/* webpackChunkName: "notification" */ '../views/channel'),
    meta: {
      title: '渠道'
    }
  },
  {
    path: '/msg',
    name: 'Msg',
    component: () => import(/* webpackChunkName: "notification" */ '../views/msg'),
    meta: {
      title: '消息中心'
    }
  },
  {
    path: '/home/account/detail',
    name: 'homeAccountDetail',
    component: () => import(/* webpackChunkName: "homeAccountDetail" */ '@/views/home/components/homeAccountDetail'),
    meta: {
      title: '余额明细'
    }
  },
  {
    path: '/home/order/detail',
    name: 'homeOrderDetail',
    component: () => import(/* webpackChunkName: "homeOrderDetail" */ '@/views/home/components/homeOrderDetail'),
    meta: {
      title: '订单概况'
    }
  },
  {
    path: '/home/contacts/detail',
    name: 'homeContactsDetail',
    component: () => import(/* webpackChunkName: "homeContactsDetail" */ '@/views/home/components/homeContactsDetail'),
    meta: {
      title: '往来明细'
    }
  },
  {
    path: '/home/price',
    name: 'homePrice',
    component: () => import(/* webpackChunkName: "homePrice" */ '@/views/home/components/homePrice'),
    meta: {
      title: '价格查询'
    }
  },
  {
    path: '/home/chart',
    name: 'homeChart',
    component: () => import(/* webpackChunkName: "homeChart" */ '@/views/home/components/homeChart'),
    meta: {
      title: '报表统计'
    }
  },
  {
    path: '/home/material/knowledge',
    name: 'homeMaterialKnowledge',
    component: () => import(/* webpackChunkName: "homeMaterialKnowledge" */ '@/views/home/components/homeMaterialKnowledge'),
    meta: {
      title: '产品知识'
    }
  },
  {
    path: '/home/bank/detail',
    name: 'homeBankDetail',
    component: () => import(/* webpackChunkName: "homeBankDetail" */ '@/views/home/components/homeBankDetail'),
    meta: {
      title: '汇款信息'
    }
  },
  {
    path: '/home/invoice',
    name: 'homeInvoice',
    component: () => import(/* webpackChunkName: "homeInvoice" */ '@/views/home/components/homeInvoiceDetail'),
    meta: {
      title: '开票信息'
    }
  },
  {
    path: '/notification',
    name: 'Notification',
    component: () => import(/* webpackChunkName: "notification" */ '../views/notification'),
    meta: {
      title: '消息'
    }
  },
  {
    path: '/message',
    name: 'Message',
    component: () => import(/* webpackChunkName: "notification" */ '../views/message'),
    meta: {
      title: '物流消息'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login'),
    meta: {
      title: '登陆',
      noLogin: true,
    }
  },
  {
    path: '/forgetPassword',
    name: 'ForgetPassword',
    component: () => import(/* webpackChunkName: "forgetPassword" */ '../views/forgetPassword'),
    meta: {
      title: '忘记密码',
      noLogin: true,
    }
  },
  {
    path: '/dataMonitor',
    name: 'DataMonitor',
    component: () => import(/* webpackChunkName: "dataMonitor" */ '../views/dataMonitor'),
    meta: {
      title: '数据监控'
    }
  },
  {
    path: '/dataMonitor/sales',
    name: 'DataMonitorSales',
    component: () => import(/* webpackChunkName: "dataMonitorSales" */ '../views/dataMonitor/sales'),
    meta: {
      title: '销量分析|数据监控'
    }
  },
  {
    path: '/dataMonitor/production',
    name: 'DataMonitorProduction',
    component: () => import(/* webpackChunkName: "dataMonitorProduction" */ '../views/dataMonitor/production'),
    meta: {
      title: '核心产品|数据监控'
    }
  },
  {
    path: '/dataMonitor/towns',
    name: 'DataMonitorTowns',
    component: () => import(/* webpackChunkName: "dataMonitorTowns" */ '../views/dataMonitor/towns'),
    meta: {
      title: '乡镇终端|数据监控'
    }
  },
  {
    path: '/dataMonitor/terminal',
    name: 'DataMonitorTerminal',
    component: () => import(/* webpackChunkName: "dataMonitorTerminal" */ '../views/dataMonitor/terminal'),
    meta: {
      title: '终端分析|数据监控'
    }
  },
  {
    path: '/dataMonitor/crops',
    name: 'DataMonitorCrops',
    component: () => import(/* webpackChunkName: "dataMonitorCrops" */ '../views/dataMonitor/crops'),
    meta: {
      title: '作物覆盖|数据监控'
    }
  },
  {
    path: '/dataMonitor/channel',
    name: 'DataMonitorChannel',
    component: () => import(/* webpackChunkName: "dataMonitorChannel" */ '../views/dataMonitor/channel'),
    meta: {
      title: '渠道变化|数据监控'
    }
  },
  {
    path: '/reports/terminal',
    name: 'ReportsTermial',
    component: () => import(/* webpackChunkName: "reportsTermial" */ '../views/reports/terminal'),
    meta: {
      title: '终端报表'
    }
  },
  {
    path: '/reports/balance',
    name: 'ReportsBalance',
    component: () => import(/* webpackChunkName: "reportsBalance" */ '../views/reports/balance'),
    meta: {
      title: '财款报表'
    }
  },
  {
    path: '/setting',
    name: 'Setting',
    component: () => import(/* webpackChunkName: "setting" */ '../views/setting'),
    meta: {
      title: '系统设置'
    }
  },
  {
    path: '/setting/changePassword',
    name: 'ChangePassword',
    component: () => import(/* webpackChunkName: "ChangePassword" */ '../views/setting/changePassword'),
    meta: {
      title: '忘记密码',
      noLogin: true,
    }
  },
  {
    path: '/setting/feedback',
    name: 'Feedback',
    component: () => import(/* webpackChunkName: "Feedback" */ '../views/setting/feedback'),
    meta: {
      title: '用户反馈'
    }
  },
  {
    path: '/message/batch',
    name: 'MessageBatch',
    component: () => import(/* webpackChunkName: "MessageBatch" */ '../views/message/batch'),
    meta: {
      title: '短信群发'
    }
  },
  {
    path: '/data/monitor/web',
    name: 'DataMonitorWeb',
    component: () => import(/* webpackChunkName: "MessageBatch" */ '../views/dataMonitor/web'),
    meta: {
      title: '数据监控'
    }
  },
  {
    path: '/message/rule',
    name: 'MessageRule',
    component: () => import(/* webpackChunkName: "MessageRule" */ '../views/message/rule'),
    meta: {
      title: '计费规则'
    }
  },
  {
    path: '/message/edit',
    name: 'MessageEdit',
    component: () => import(/* webpackChunkName: "MessageEdit" */ '../views/message/edit'),
    meta: {
      title: '短信编辑',
    }
  },
  {
    path: '/message/template',
    name: 'MessageTemplate',
    component: () => import(/* webpackChunkName: "MessageTemplate" */ '../views/message/template'),
    meta: {
      title: '短信模板'
    }
  },
  {
    path: '/message/list',
    name: 'MessageList',
    component: () => import(/* webpackChunkName: "MessageList" */ '../views/message/list'),
    meta: {
      title: '短信列表',
      noKeepAlive: true,
    }
  },
  {
    path: '/message/detail',
    name: 'MessageDetail',
    component: () => import(/* webpackChunkName: "MessageDetail" */ '../views/message/detail'),
    meta: {
      title: '短信详情'
    }
  },
  {
    path: '/map',
    name: 'Map',
    component: () => import(/* webpackChunkName: "Map" */ '../views/map'),
    meta: {
      title: '地图'
    }
  },
  {
    path: '/map/list',
    name: 'MapList',
    component: () => import(/* webpackChunkName: "MapList" */ '../views/map/list'),
    meta: {
      title: '地图列表'
    }
  },
  {
    path: '/map/trace',
    name: 'MapTrace',
    component: () => import(/* webpackChunkName: "MapTrace" */ '../views/map/trace'),
    meta: {
      title: '轨迹回放',
      noKeepAlive: true,
    }
  },

  {
    path: '/map/routerpath',
    name: 'MapRouterpath',
    component: () => import(/* webpackChunkName: "MapTrace" */ '../views/map/routerpath'),
    meta: {
      title: '轨迹详情',
      noKeepAlive: true,
    }
  },

  {
    path: '/order/routerpath',
    name: 'OrderRouterpath',
    component: () => import(/* webpackChunkName: "OrderRouterpath" */ '../views/order/routerpath'),
    meta: {
      title: '运输轨迹',
      noKeepAlive: true,
    }
  },


  {
    path: '/address/map',
    name: 'AddressMap',
    component: () => import(/* webpackChunkName: "AddressMap" */ '../views/address/map'),
    meta: {
      title: '选择地址'
    }
  },
  {
    path: '/address/boat',
    name: 'AddressBoat',
    component: () => import(/* webpackChunkName: "AddressBoat" */ '../views/address/boat'),
    meta: {
      title: '新增地址',
      // noKeepAlive: true,
    }
  },
  {
    path: '/address/add',
    name: 'AddressAdd',
    component: () => import(/* webpackChunkName: "AddressBoat" */ '../views/shidanliBusiness/address/add'),
    meta: {
      title: '新增地址',
      // noKeepAlive: true,
    }
  },
  /********************************/
  /********************************/
  /********************************/
  // 空白区域
  {
    path: '/emptyArea',
    name: 'EmptyArea',
    component: () => import(/* webpackChunkName: "emptyArea" */ '@/views/emptyArea'),
    meta: {
      title: '空白区域'
    }
  },
  {
    path: '/emptyArea/filter',
    name: 'EmptyAreaFilter',
    component: () => import(/* webpackChunkName: "emptyArea" */ '@/views/emptyArea/Filter'),
    meta: {
      title: '空白区域筛选'
    }
  },
  {
    path: '/emptyArea/info/:id',
    name: 'EmptyAreaInfo',
    component: () => import(/* webpackChunkName: "emptyArea" */ '@/views/emptyArea/Info'),
    meta: {
      title: '空白村拜访',
    },
  },
  // 农技会
  {
    path: '/agriculturalTechnology',
    name: 'AgriculturalTechnology',
    component: () => import(/* webpackChunkName: "agriculturalTechnology" */ '@/views/agriculturalTechnology'),
    meta: {
      title: '农技会列表'
    }
  },
  {
    path: '/agriculturalTechnology/filter',
    name: 'AgriculturalTechnologyFilter',
    component: () => import(/* webpackChunkName: "agriculturalTechnology" */ '@/views/agriculturalTechnology/Filter'),
    meta: {
      title: '农技会列表筛选'
    }
  },
  {
    path: '/agriculturalTechnology/add',
    name: 'AgriculturalTechnologyAdd',
    component: () => import(/* webpackChunkName: "agriculturalTechnology" */ '@/views/agriculturalTechnology/Add'),
    meta: {
      title: '新增农技会',
    }
  },
  {
    path: '/agriculturalTechnology/addFertilizer/:id',
    name: 'AgriculturalTechnologyAddFertilizer',
    component: () => import(/* webpackChunkName: "agriculturalTechnology" */ '@/views/agriculturalTechnology/AddFertilizer'),
    meta: {
      title: '新增订肥信息',
    }
  },
  {
    path: '/agriculturalTechnology/shidanliDetail/:id',
    name: 'AgriculturalTechnologyShidanliDetail',
    component: () => import(/* webpackChunkName: "agriculturalTechnology" */ '@/views/agriculturalTechnology/ShidanliDetail'),
    meta: {
      title: '史丹利农技会详情'
    }
  },
  {
    path: '/agriculturalTechnology/PersonDetail/:id',
    name: 'AgriculturalTechnologyPersonDetail',
    component: () => import(/* webpackChunkName: "agriculturalTechnology" */ '@/views/agriculturalTechnology/PersonDetail'),
    meta: {
      title: '自有农技会详情'
    }
  },
  // 潜在客户
  {
    path: '/potentialCustomers',
    name: 'potentialCustomers',
    component: () => import(/* webpackChunkName: "potentialCustomers" */ '@/views/potentialCustomers'),
    meta: {
      title: '潜在客户列表'
    }
  },
  {
    path: '/potentialCustomers/filter',
    name: 'potentialCustomersFilter',
    component: () => import(/* webpackChunkName: "potentialCustomers" */ '@/views/potentialCustomers/Filter'),
    meta: {
      title: '潜在客户列表筛选'
    }
  },
  {
    path: '/potentialCustomers/add',
    name: 'potentialCustomersAdd',
    component: () => import(/* webpackChunkName: "potentialCustomers" */ '@/views/potentialCustomers/Add'),
    meta: {
      title: '新增潜在客户',
    }
  },
  {
    path: '/potentialCustomers/shidanliDetail/:id',
    name: 'potentialCustomersShidanliDetail',
    component: () => import(/* webpackChunkName: "potentialCustomers" */ '@/views/potentialCustomers/ShidanliDetail'),
    meta: {
      title: '史丹利潜在客户详情'
    }
  },
  {
    path: '/potentialCustomers/personDetail/:id',
    name: 'potentialCustomersPersonDetail',
    component: () => import(/* webpackChunkName: "potentialCustomers" */ '@/views/potentialCustomers/PersonDetail'),
    meta: {
      title: '自有潜在客户详情'
    }
  },
  {
    path: '/potentialCustomers/shidanliInfo/:id',
    name: 'potentialCustomersShidanliInfo',
    component: () => import(/* webpackChunkName: "potentialCustomers" */ '@/views/potentialCustomers/ShidanliInfo'),
    meta: {
      title: '史丹利潜在客户拜访',
    }
  },
  {
    path: '/potentialCustomers/personInfo/:id',
    name: 'potentialCustomersPersonInfo',
    component: () => import(/* webpackChunkName: "potentialCustomers" */ '@/views/potentialCustomers/PersonInfo'),
    meta: {
      title: '自有潜在客户拜访',
    }
  },
  // 示范田
  {
    path: '/demonstrationField',
    name: 'DemonstrationField',
    component: () => import(/* webpackChunkName: "demonstrationField" */ '@/views/demonstrationField'),
    meta: {
      title: '示范田列表'
    }
  },
  {
    path: '/demonstrationField/filter',
    name: 'DemonstrationFieldFilter',
    component: () => import(/* webpackChunkName: "demonstrationField" */ '@/views/demonstrationField/Filter'),
    meta: {
      title: '示范田列表筛选'
    }
  },
  {
    path: '/demonstrationField/add',
    name: 'DemonstrationFieldAdd',
    component: () => import(/* webpackChunkName: "demonstrationField" */ '@/views/demonstrationField/Add'),
    meta: {
      title: '新增自有示范田',
    }
  },
  {
    path: '/demonstrationField/shidanliDetail/:id',
    name: 'DemonstrationFieldShidanliDetail',
    component: () => import(/* webpackChunkName: "demonstrationField" */ '@/views/demonstrationField/ShidanliDetail'),
    meta: {
      title: '史丹利示范田详情'
    }
  },
  {
    path: '/demonstrationField/personDetail/:id',
    name: 'DemonstrationFieldPersonDetail',
    component: () => import(/* webpackChunkName: "demonstrationField" */ '@/views/demonstrationField/PersonDetail'),
    meta: {
      title: '自有示范田详情'
    }
  },
  {
    path: '/demonstrationField/shidanliInfo/:id',
    name: 'DemonstrationFieldShidanliInfo',
    component: () => import(/* webpackChunkName: "demonstrationField" */ '@/views/demonstrationField/ShidanliInfo'),
    meta: {
      title: '史丹利示范田拜访',
    }
  },
  {
    path: '/demonstrationField/personInfo/:id',
    name: 'DemonstrationFieldPersonInfo',
    component: () => import(/* webpackChunkName: "demonstrationField" */ '@/views/demonstrationField/PersonInfo'),
    meta: {
      title: '自有示范田拜访',
    }
  },
  // 业务
  {
    path: '/shidanliBusiness',
    name: 'ShidanliBusiness',
    component: () => import(/* webpackChunkName: "shidanliBusiness" */ '@/views/shidanliBusiness'),
    meta: {
      title: '我的团队'
    }
  },
  {
    path: '/shidanliBusiness/address',
    name: 'ShidanliBusinessAddress',
    component: () => import(/* webpackChunkName: "shidanliBusinessAddress" */ '@/views/shidanliBusiness/address/index'),
    meta: {
      title: '收货地址'
    }
  },
  {
    path: '/bank/child',
    name: 'BankChild',
    component: () => import(/* webpackChunkName: "shidanliBusinessAddress" */ '@/views/my/bank/index'),
    meta: {
      title: '银行卡维护'
    }
  },
  {
    path: '/bank/child/add',
    name: 'BankChildAdd',
    component: () => import(/* webpackChunkName: "AddressBoat" */ '@/views/my/bank/add'),
    meta: {
      title: '银行卡新增/修改',
      // noKeepAlive: true,
    }
  },
  {
    path: '/bank/child/record',
    name: 'BankChildRecord',
    component: () => import(/* webpackChunkName: "AddressBoat" */ '@/views/my/bank/record'),
    meta: {
      title: '修改记录',
      // noKeepAlive: true,
    }
  },
  {
    path: '/shidanliBusiness/add',
    name: 'ShidanliBusinessAdd',
    component: () => import(/* webpackChunkName: "shidanliBusiness" */ '@/views/shidanliBusiness/Add'),
    meta: {
      title: '新增属地业务员',
    }
  },
  {
    path: '/shidanliBusiness/memberDetail/:id',
    name: 'ShidanliBusinessMemberDetail',
    component: () => import(/* webpackChunkName: "shidanliBusiness" */ '@/views/shidanliBusiness/MemberDetail'),
    meta: {
      title: '成员信息'
    }
  },
  {
    path: '/shidanliBusiness/terminals',
    name: 'ShidanliBusinessTerminals',
    component: () => import(/* webpackChunkName: "shidanliBusiness" */ '@/views/shidanliBusiness/Terminals'),
    meta: {
      title: '终端列表'
    }
  },
  {
    path: '/shidanliBusiness/knowledge',
    name: 'ShidanliBusinessKnowledge',
    component: () => import(/* webpackChunkName: "shidanliBusiness" */ '@/views/shidanliBusiness/Knowledge'),
    meta: {
      title: '产品知识'
    }
  },
  {
    path: '/agro/detail',
    name: 'AgroDetail',
    component: () => import(/* webpackChunkName: "shidanliBusiness" */ '@/views/home/components/agroDetail'),
    meta: {
      title: '农化知识'
    }
  },
  {
    path: '/agro/detailFilter',
    name: 'AgroDetailFilter',
    component: () => import(/* webpackChunkName: "shidanliBusiness" */ '@/views/home/components/agroDetailFilter'),
    meta: {
      title: '农化知识搜索'
    }
  },
    {
        path: '/agro/info',
        name: 'AgroInfo',
        component: () => import(/* webpackChunkName: "teachingMaterial" */ '@/views/home/components/agroInfo'),
        meta: {
            title: '教材详情',
            noKeepAlive: true
        }
    },
  {
    path: '/agro/info/web/:id',
    name: 'AgroInfoWeb',
    component: () => import(/* webpackChunkName: "teachingMaterial" */ '@/views/home/components/agroInfoWeb'),
    meta: {
      title: '教材详情',
      noKeepAlive: true
    }
  },
  {
    path: '/shidanliBusiness/knowledgeDetail/:id',
    name: 'ShidanliBusinessKnowledgeDetail',
    component: () => import(/* webpackChunkName: "shidanliBusiness" */ '@/views/shidanliBusiness/KnowledgeDetail'),
    meta: {
      title: '产品知识详情',
    }
  },
  // 终端网点
  {
    path: '/terminalOutlets',
    name: 'TerminalOutlets',
    component: () => import(/* webpackChunkName: "terminalOutlets" */ '@/views/terminalOutlets'),
    meta: {
      title: '终端网点列表'
    }
  },
  {
    path: '/terminalOutlets/filter',
    name: 'TerminalOutletsFilter',
    component: () => import(/* webpackChunkName: "terminalOutlets" */ '@/views/terminalOutlets/Filter'),
    meta: {
      title: '终端网点列表筛选'
    }
  },
  {
    path: '/terminalOutlets/add',
    name: 'TerminalOutletsAdd',
    component: () => import(/* webpackChunkName: "terminalOutlets" */ '@/views/terminalOutlets/Add'),
    meta: {
      title: '新增终端网点',
    }
  },
  {
    path: '/terminalOutlets/shidanliDetail/:id',
    name: 'TerminalOutletsShidanliDetail',
    component: () => import(/* webpackChunkName: "terminalOutlets" */ '@/views/terminalOutlets/ShidanliDetail'),
    meta: {
      title: '史丹利终端网点详情'
    }
  },
  {
    path: '/terminalOutlets/personDetail/:id',
    name: 'TerminalOutletsPersonDetail',
    component: () => import(/* webpackChunkName: "terminalOutlets" */ '@/views/terminalOutlets/PersonDetail'),
    meta: {
      title: '自有终端网点详情'
    }
  },
  {
    path: '/terminalOutlets/shidanliInfo/:id',
    name: 'TerminalOutletsShidanliInfo',
    component: () => import(/* webpackChunkName: "terminalOutlets" */ '@/views/terminalOutlets/ShidanliInfo'),
    meta: {
      title: '史丹利终端网点拜访',
    }
  },
  {
    path: '/terminalOutlets/personInfo/:id',
    name: 'TerminalOutletsPersonInfo',
    component: () => import(/* webpackChunkName: "terminalOutlets" */ '@/views/terminalOutlets/PersonInfo'),
    meta: {
      title: '自有终端网点拜访',
    }
  },
  {
    path: '/terminalOutlets/shidanliCheck/:id',
    name: 'TerminalOutletsShidanliCheck',
    component: () => import(/* webpackChunkName: "terminalOutlets" */ '@/views/terminalOutlets/ShidanliCheck'),
    meta: {
      title: '史丹利终端网点检核',
    }
  },
  {
    path: '/terminalOutlets/villageCover/:id',
    name: 'TerminalOutletsVillageCover',
    component: () => import(/* webpackChunkName: "terminalOutlets" */ '@/views/terminalOutlets/VillageCover'),
    meta: {
      title: '终端乡村覆盖',
      noKeepAlive: true,
    }
  },
  {
    path: '/terminalOutlets/villageCover/addSelectTown',
    name: 'TerminalOutletsVillageCoverAddSelectTown',
    component: () => import(/* webpackChunkName: "terminalOutlets" */ '@/views/terminalOutlets/VillageCoverAddSelectTown'),
    meta: {
      title: '选择乡镇',
      noKeepAlive: true
    }
  },
  {
    path: '/terminalOutlets/villageCover/addSelectVillage',
    name: 'TerminalOutletsVillageCoverAddSelectVillage',
    component: () => import(/* webpackChunkName: "terminalOutlets" */ '@/views/terminalOutlets/VillageCoverAddSelectVillage'),
    meta: {
      title: '终端乡村覆盖',
      noKeepAlive: true
    }
  },
  {
    path: '/terminalOutlets/cropCover/:id',
    name: 'TerminalOutletsCropCover',
    component: () => import(/* webpackChunkName: "terminalOutlets" */ '@/views/terminalOutlets/CropCover'),
    meta: {
      title: '终端作物覆盖',
      noKeepAlive: true,
    }
  },
  {
    path: '/terminalOutlets/cropCover/addropCover',
        name: 'TerminalOutletsCropCoverAdd',
      component: () => import(/* webpackChunkName: "terminalOutlets" */ '@/views/terminalOutlets/CropCoverAdd'),
      meta: {
    title: '添加作物覆盖',
        noKeepAlive: true,
  }
  },
  // 种植大户
  {
    path: '/bigGrowers',
    name: 'BigGrowers',
    component: () => import(/* webpackChunkName: "bigGrowers" */ '@/views/bigGrowers'),
    meta: {
      title: '种植大户列表'
    }
  },
  {
    path: '/bigGrowers/filter',
    name: 'BigGrowersFilter',
    component: () => import(/* webpackChunkName: "bigGrowers" */ '@/views/bigGrowers/Filter'),
    meta: {
      title: '种植大户列表筛选'
    }
  },
  {
    path: '/bigGrowers/add',
    name: 'BigGrowersAdd',
    component: () => import(/* webpackChunkName: "bigGrowers" */ '@/views/bigGrowers/Add'),
    meta: {
      title: '添加种植大户',
    }
  },
  {
    path: '/bigGrowers/shidanliDetail/:id',
    name: 'BigGrowersShidanliDetail',
    component: () => import(/* webpackChunkName: "bigGrowers" */ '@/views/bigGrowers/ShidanliDetail'),
    meta: {
      title: '种植大户详情'
    }
  },
  {
    path: '/bigGrowers/personDetail/:id',
    name: 'BigGrowersPersonDetail',
    component: () => import(/* webpackChunkName: "bigGrowers" */ '@/views/bigGrowers/PersonDetail'),
    meta: {
      title: '种植大户详情'
    }
  },
  {
    path: '/bigGrowers/shidanliInfo/:id',
    name: 'BigGrowersShidanliInfo',
    component: () => import(/* webpackChunkName: "bigGrowers" */ '@/views/bigGrowers/ShidanliInfo'),
    meta: {
      title: '史丹利种植大户拜访',
    }
  },
  {
    path: '/bigGrowers/personInfo/:id',
    name: 'BigGrowersPersonInfo',
    component: () => import(/* webpackChunkName: "bigGrowers" */ '@/views/bigGrowers/PersonInfo'),
    meta: {
      title: '自有种植大户拜访',
    }
  },
  /********************************/
  /********************************/
  /********************************/
  {
    path: '/plainText/help',
    name: 'PlainTextHelp',
    component: () => import(/* webpackChunkName: "plainText" */ '@/views/plainText/Help'),
    meta: {
      title: '问题帮助 '
    }
  },
  {
    path: '/plainText/agreement',
    name: 'PlainTextAgreement',
    component: () => import(/* webpackChunkName: "plainText" */ '@/views/plainText/Agreement'),
    meta: {
      title: '用户协议',
      noLogin: true,
    }
  },
  {
    path: '/my/index',
    name: 'MyIndex',
    component: () => import(/* webpackChunkName: "my" */ '@/views/my/index'),
    meta: {
      title: '我的信息'
    }
  },
  {
    path: '/meeting/meetingList',
    name: 'MeetingList',
    component: () => import(/* webpackChunkName: "meeting" */ '@/views/meeting/meetingList'),
    meta: {
      title: '会议信息'
    }
  },
  {
    path: '/complaint/checkComplaint',
    name: 'CheckComplaint',
    component: () => import(/* webpackChunkName: "complaint" */ '@/views/complaint/checkComplaint'),
    meta: {
      title: '查看客诉'
    }
  },
  {
    path: '/complaint/index',
    name: 'ComplaintIndex',
    component: () => import(/* webpackChunkName: "complaint" */ '@/views/complaint/index'),
    meta: {
      title: '客户投诉'
    }
  },
  {
    path: '/home/components/targetDetail',
    name: 'TargetDetail',
    component: () => import(/* webpackChunkName: "target" */ '@/views/home/components/targetDetail'),
    meta: {
      title: '我的目标'
    }
  },
  {
    path: '/sureTarget',
    name: 'SureTarget',
    component: () => import(/* webpackChunkName: "target" */ '@/views/login/sureTarget'),
    meta: {
      title: '目标确认'
    }
  },
  {
    path: '/home/components/priceDetail',
    name: 'PriceDetail',
    component: () => import(/* webpackChunkName: "price" */ '@/views/home/components/priceDetail'),
    meta: {
      title: '价格查询'
    }
  },
  {
    path: '/my/price/index',
    name: 'ProductPriceDetail',
    component: () => import(/* webpackChunkName: "price" */ '@/views/my/price/index'),
    meta: {
      title: '价格查询'
    }
  },
  {
    path: '/order/index',
    name: 'OrderIndex',
    component: () => import(/* webpackChunkName: "order" */ '@/views/order/index'),
    meta: {
      title: '订单列表'
    }
  },
  {
    path: '/order/filterOrder',
    name: 'OrderFilterOrder',
    component: () => import(/* webpackChunkName: "order" */ '@/views/order/filterOrder'),
    meta: {
      title: '筛选订单'
    }
  },
  {
    path: '/order/addOrderFirst',
    name: 'OrderAddOrderFirst',
    component: () => import(/* webpackChunkName: "order" */ '@/views/order/addOrderFirst'),
    meta: {
      title: '增加订单',
    }
  },
  {
    path: '/order/addOrderSecond',
    name: 'OrderAddOrderSecond',
    component: () => import(/* webpackChunkName: "order" */ '@/views/order/addOrderSecond'),
    meta: {
      title: '增加订单'
    }
  },
  {
    path: '/order/addOrderThird',
    name: 'OrderAddOrderThird',
    component: () => import(/* webpackChunkName: "order" */ '@/views/order/addOrderThird'),
    meta: {
      title: '增加订单',
    }
  },
  {
    path: '/order/detail',
    name: 'OrderDetail',
    component: () => import(/* webpackChunkName: "order" */ '@/views/order/detail'),
    meta: {
      title: '订单详情',
      noKeepAlive: true
    }
  },
  {
    path: '/info/carrierInfo',
    name: 'InfoCarrierInfo',
    component: () => import(/* webpackChunkName: "info" */ '@/views/info/carrierInfo'),
    meta: {
      title: '承运人信息'
    }
  },
  {
    path: '/info/logisticsInfo',
    name: 'InfoLogisticsInfo',
    component: () => import(/* webpackChunkName: "info" */ '@/views/info/logisticsInfo'),
    meta: {
      title: '物流竞价信息'
    }
  },
  {
    path: '/visit/list',
    name: 'VisitList',
    component: () => import(/* webpackChunkName: "visit" */ '@/views/visit/list'),
    meta: {
      title: '近期拜访记录',
      noKeepAlive: true
    }
  },
  {
    path: '/visit/detail',
    name: 'VisitDetail',
    component: () => import(/* webpackChunkName: "visit" */ '@/views/visit/detail'),
    meta: {
      title: '拜访详情',
      noKeepAlive: true
    }
  },
  {
    path: '/info/accountBalance',
    name: 'InfoAccountBalance',
    component: () => import(/* webpackChunkName: "info" */ '@/views/info/accountBalance'),
    meta: {
      title: '账户余额详情',
      noKeepAlive: true
    }
  },
  {
    path: '/info/contactsInfo',
    name: 'InfoContactsInfo',
    component: () => import(/* webpackChunkName: "info" */ '@/views/info/contactsInfo'),
    meta: {
      title: '账户往来明细'
    }
  },
  {
    path: '/info/invoiceInfo',
    name: 'InfoInvoiceInfo',
    component: () => import(/* webpackChunkName: "info" */ '@/views/info/invoiceInfo'),
    meta: {
      title: '电子发票信息'
    }
  },
  {
    path: '/info/storeInfo',
    name: 'InfoStoreInfo',
    component: () => import(/* webpackChunkName: "info" */ '@/views/info/storeInfo'),
    meta: {
      title: '本竞品库存'
    }
  },
  {
    path: '/bill/terminalDetail',
    name: 'BillTerminalDetail',
    component: () => import(/* webpackChunkName: "bill" */ '@/views/bill/terminalDetail'),
    meta: {
      title: '终端账款详情',
      // noKeepAlive: true
    }
  },
  {
    path: '/bill/bigGrowersDetail',
    name: 'BillBigGrowersDetail',
    component: () => import(/* webpackChunkName: "bill" */ '@/views/bill/bigGrowersDetail'),
    meta: {
      title: '种植大户账款详情',
      // noKeepAlive: true
    }
  },
  {
    path: '/bill/manage',
    name: 'BillManage',
    component: () => import(/* webpackChunkName: "bill" */ '@/views/bill/manage'),
    meta: {
      title: '账款管理',
      // noKeepAlive: true
    }
  },
  {
    path: '/bill/terminalManage/:terminalType/:terminalId',
    name: 'BillTerminalManage',
    component: () => import(/* webpackChunkName: "bill" */ '@/views/bill/terminalManage'),
    meta: {
      title: '终端账款管理',
      // noKeepAlive: true
    }
  },
  {
    path: '/bill/bigGrowersManage/:bigfarmersType/:bigfarmersId',
    name: 'BillBigGrowersManage',
    component: () => import(/* webpackChunkName: "bill" */ '@/views/bill/bigGrowersManage'),
    meta: {
      title: '种植大户账款管理'
    }
  },
  {
    path: '/bill/bigGrowersAdd',
    name: 'BillBigGrowersAdd',
    component: () => import(/* webpackChunkName: "bill" */ '@/views/bill/bigGrowersAdd'),
    meta: {
      title: '种植大户新增账款',
      // noKeepAlive: true
    }
  },
  {
    path: '/bill/terminalAdd',
    name: 'BillTerminalAdd',
    component: () => import(/* webpackChunkName: "bill" */ '@/views/bill/terminalAdd'),
    meta: {
      title: '终端新增账款',
      // noKeepAlive: true
    }
  },
  {
    path: '/teachingMaterial/list',
    name: 'TeachingMaterialList',
    component: () => import(/* webpackChunkName: "teachingMaterial" */ '@/views/teachingMaterial/list'),
    meta: {
      title: '教材列表'
    }
  },
  {
    path: '/teachingMaterial/detail',
    name: 'TeachingMaterialDetail',
    component: () => import(/* webpackChunkName: "teachingMaterial" */ '@/views/teachingMaterial/detail'),
    meta: {
      title: '教材详情',
      noKeepAlive: true
    }
  },
  {
    path: '/task/list',
    name: 'TaskList',
    component: () => import(/* webpackChunkName: "task" */ '@/views/task/list'),
    meta: {
      title: '任务列表'
    }
  },
  {
    path: '/task/listStatus',
    name: 'TaskListStatus',
    component: () => import(/* webpackChunkName: "task" */ '@/views/task/listWithStatus'),
    meta: {
      title: '任务列表'
    }
  },
  {
    path: '/task/publishTask',
    name: 'TaskPublishTask',
    component: () => import(/* webpackChunkName: "task" */ '@/views/task/publishTask'),
    meta: {
      title: '发布任务',
      noKeepAlive: true
    }
  },
  {
    path: '/task/detail/:id',
    name: 'TaskDetail',
    component: () => import(/* webpackChunkName: "task" */ '@/views/task/detail'),
    meta: {
      title: '任务详情',
      // noKeepAlive: true
    }
  },
  {
    path: '/task/sendList/',
    name: 'TaskSendList',
    component: () => import(/* webpackChunkName: "task" */ '@/views/task/sendList'),
    meta: {
      title: '抄送者列表'
    }
  },
  {
    path: '/task/receiveList/',
    name: 'TaskReceiveList',
    component: () => import(/* webpackChunkName: "task" */ '@/views/task/sendList'),
    meta: {
      title: '接收者列表'
    }
  },
  {
    path: '/task/finishTask',
    name: 'TaskFinishTask',
    component: () => import(/* webpackChunkName: "task" */ '@/views/task/finishTask'),
    meta: {
      title: '完成任务'
    }
  },
  {
    path: '/sales/terminalSales',
    name: 'SalesTerminalSales',
    component: () => import(/* webpackChunkName: "sales" */ '@/views/sales/terminalSales'),
    meta: {
      title: '终端销量'
    }
  },
  {
    path: '/sales/addNew',
    name: 'SalesAddNew',
    component: () => import(/* webpackChunkName: "sales" */ '@/views/sales/addNew'),
    meta: {
      title: '新增销量',
      noKeepAlive: true
    }
  },
  {
    path: '/sales/yearSales',
    name: 'SalesYearSales',
    component: () => import(/* webpackChunkName: "sales" */ '@/views/sales/yearSales'),
    meta: {
      title: '本季度配比销量',
      noKeepAlive: true
    }
  },
  {
    path: '/order/download',
    name: 'OrderDownload',
    component: () => import(/* webpackChunkName: "sales" */ '@/views/order/download'),
    meta: {
      title: '下载'
    }
  },
  {
    path: '/my/back/index',
    name: 'myBack',
    component: () => import(/* webpackChunkName: "homeAccountDetail" */ '@/views/my/back/index'),
    meta: {
      title: '返利明细'
    }
  },
  {
    path: '/my/back/add',
    name: 'myBackAdd',
    component: () => import(/* webpackChunkName: "homeAccountDetail" */ '@/views/my/back/add'),
    meta: {
      title: '新增返利明细'
    }
  },
  {
    path: '/my/back/reduce',
    name: 'myBackReduce',
    component: () => import(/* webpackChunkName: "homeAccountDetail" */ '@/views/my/back/reduce'),
    meta: {
      title: '耗用返利明细'
    }
  },
    
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
