export const login = data => {
  localStorage.setItem('sysUser', JSON.stringify(data.sysUser))
  localStorage.setItem('userName', data.sysUser.userName);
  localStorage.setItem('token', data.sysUser.h5Token)
  localStorage.setItem('nickName', data.sysUser.nickName);
  localStorage.setItem('phoneNumber', data.sysUser.phonenumber);
  localStorage.setItem('loginName', data.sysUser.loginName);
  localStorage.setItem('userId', data.sysUser.userId)
  localStorage.setItem('openId', data.openId)
  localStorage.setItem('userType', data.sysUser.userType == 2 ? '属地业务' : '境内销售')
}

export const logout = () => {
  localStorage.setItem('sysUser', '')
  localStorage.setItem('userName', '');
  localStorage.setItem('token', '')
  localStorage.setItem('nickName', '');
  localStorage.setItem('phoneNumber', '');
  localStorage.setItem('loginName', '');
  localStorage.setItem('userId', '')
  // localStorage.setItem('openId', '')
  localStorage.setItem('userType', '')
}

export const setLastUserName = userName => {
  localStorage.setItem('lastUserName', userName)
}

export const getToken = () => {
  return localStorage.getItem('token');
}
export const getLastUserName = () => {
  return localStorage.getItem('lastUserName');
}


export const getUserName = () => {
  return localStorage.getItem('userName');
}

export const getNickName = () => {
  return localStorage.getItem('nickName');
}

export const getOpenId = () => {
  return localStorage.getItem('openId');
}

export const setOpenId = openId => {
  localStorage.setItem('openId', openId)
}

export const getPhoneNumber = () => {
  return localStorage.getItem('phoneNumber');
}

export const getLoginName = () => {
  return localStorage.getItem('loginName');
}

export const getUserId = () => {
  return localStorage.getItem('userId')
}

export const getUserType = () => {
  return localStorage.getItem('userType')
}

export const getRoles = () => {
  return JSON.parse(localStorage.getItem('sysUser')).roles[0]
}

export const getSysUser = () => {
  return JSON.parse(localStorage.getItem('sysUser'))
}

export const getDealerName = () => {
  return JSON.parse(localStorage.getItem('sysUser')).dealerName
}

export const getDealerId = () => {
  return JSON.parse(localStorage.getItem('sysUser'))?.dealer
}